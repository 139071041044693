import { TOKEN_KEY } from "@/common/constants";
import Cookies from "js-cookie";
import { Message } from "element-ui";

const turn = {
  error(code) {
    // 401: token失效，需要重新登录；
    // 403: 无相关页面访问权限;
    // 404: 其它错误-一律走404;
    if (code == "401") {
      // 登陆过期
      Cookies.remove(TOKEN_KEY);
      Message.error("token过期，请重新登陆");
      location.href = "/login";
    }
    if (code == "403") {
      location.href = "/nopermission";
    }
    if (code == "404") {
      location.href = "/404";
    }
  },
};

export default turn;
