<template>
  <div>
    <content-header
      :breadList="['用户价值报告', '趋势分析']"
      style="margin-bottom: 0px; border-bottom: 0; height: 43px"
    />
    <div v-loading="loading">
      <iframe :src="url" frameborder="0" width="100%" :height="height"></iframe>
    </div>
  </div>
</template>

<script>
// 趋势分析
import Cookies from "js-cookie";
import { TOKEN_KEY } from "@/common/constants";

import ContentHeader from "@/views/layout/content-header";
import turn from "@/util/turn";

export default {
  components: {
    ContentHeader,
  },
  data() {
    return {
      url: null,
      height: 0,
      loading: true,
      title: this.$route.meta.title,
    };
  },
  methods: {
    getyiqixiu() {
      this.loading = true;
      const query = this.$route.query;
      if (query && query.url) {
        this.url = query.url;
      } else {
        const userToken = Cookies.get(TOKEN_KEY);
        this.url = `${window.location.origin}/cdpbi/#/trend-analysis?Token=${userToken}`;
      }

      this.loading = false;
    },
    setHeight() {
      const elMain = document.getElementsByClassName("el-main")[0];
      this.height = elMain.offsetHeight - 45 - 95 + "px";
    },
    message(event) {
      if (event.data.type == "OPEN_PAGE") {
        console.log(event.data);
        if (event.data.data.name == "趋势分析") {
          this.$router.push({
            path: "/cdpbi/trend-analysis",
            query: { url: event.data.data.url },
          });
        }
        if (event.data.data.name == "客户合并") {
          this.$router.push({
            path: "/cdpbi/cus-merge",
            query: { url: event.data.data.url },
          });
        }
        if (event.data.data.name == "整体分析") {
          this.$router.push({
            path: "/cdpbi/overall-analysis",
            query: { url: event.data.data.url },
          });
        }
      } else if (event.data.type == "ERROR_CODE") {
        turn.error(event.data.data.code);
      }
    },
  },
  created() {
    this.getyiqixiu();
  },
  mounted() {
    this.setHeight();
    window.addEventListener("resize", this.setHeight);
    window.addEventListener("message", this.message);
  },
  destroyed() {
    window.removeEventListener("resize", this.setHeight, false);
    window.removeEventListener("message", this.message, false);
  },
};
</script>

<style lang="less" scoped></style>
